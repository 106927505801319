import { useState } from "react";
import About from "../about/about";
import "./welcome.css"
import bg from "../Assets/ClubVollocity_Logo.png"



export default function Welcome(props) {
    const [isOpen, setOpen] = useState(false);

    return (
        <div className="Welcomediv" style={{backgroundImage: `url(${bg}`}}>
            <button className="SignButton"> <a href="https://docs.google.com/document/d/1GPO4btItWiLqf81pjusdje7SmWxB1adX/edit?usp=sharing&ouid=105499710533681496050&rtpof=true&sd=true" target="blank"> Sign-Up Now! </a> </button>
            <button className="SignButton" onClick={(e) => {
                props.setView(false);
            }}>Visit Full Site</button>
        </div>
    )
}